// chains/chains.ts
import { rinkeby, mainnet, goerli } from "wagmi/chains";
var avalandche = {
  id: 43114,
  name: "Avalanche C-Chain",
  network: "avalanche",
  rpcUrls: {
    default: "https://rpc.ankr.com/avalanche"
  },
  nativeCurrency: { name: "Avalanche", symbol: "AVAX", decimals: 18 },
  blockExplorers: {
    default: {
      name: "snowtrace",
      url: "https://snowtrace.io/"
    }
  }
};
var avalandcheFuji = {
  id: 43113,
  name: "Avalanche Fuji",
  network: "avalanche-fuji",
  rpcUrls: {
    default: "https://rpc.ankr.com/avalanche_fuji"
  },
  nativeCurrency: { name: "Avalanche", symbol: "AVAX", decimals: 18 },
  blockExplorers: {
    default: {
      name: "snowtrace",
      url: "https://testnet.snowtrace.io/"
    }
  },
  testnet: true
};
var fantomTestnet = {
  id: 4002,
  name: "Fantom Testnet",
  network: "fantom-testnet",
  nativeCurrency: { name: "Fantom", symbol: "FTM", decimals: 18 },
  rpcUrls: {
    default: "https://rpc.testnet.fantom.network"
  },
  blockExplorers: {
    default: {
      name: "FTMScan",
      url: "https://testnet.ftmscan.com"
    }
  },
  testnet: true
};
var bscExplorer = { name: "BscScan", url: "https://bscscan.com" };
var bsc = {
  id: 56,
  name: "Binance Smart Chain",
  network: "BNB",
  rpcUrls: {
    public: "https://bsc-dataseed1.binance.org",
    default: "https://bsc-dataseed1.binance.org"
  },
  blockExplorers: {
    default: bscExplorer,
    etherscan: bscExplorer
  },
  nativeCurrency: {
    name: "Binance Smart Chain",
    symbol: "BNB",
    decimals: 18
  },
  multicall: {
    address: "0x80a804EAa238770a7f853e3222cC3D69213F9a14",
    blockCreated: 12300629
  }
};
var bscTest = {
  id: 786,
  name: "VOX",
  network: "BNB",
  nativeCurrency: {
    decimals: 18,
    name: "Volrex.Network",
    symbol: "VOX"
  },
  rpcUrls: {
    public: "https://walletnode.explorersgc.com/",
    default: "https://walletnode.explorersgc.com/"
  },
  blockExplorers: {
    default: { name: "VOX", url: "https://explorersgc.com" }
  },
  multicall: {
    address: "0x80a804EAa238770a7f853e3222cC3D69213F9a14",
    blockCreated: 87973703
  },
  testnet: true
};
var ethereum = {
  id: 1,
  name: "Ethereum",
  network: "ETH",
  nativeCurrency: {
    decimals: 18,
    name: "Ethereum",
    symbol: "ETH"
  },
  rpcUrls: {
    public: "https://mainnet.infura.io/v3/d5c09c17fb5446728d3818bc4afeca69",
    default: "https://mainnet.infura.io/v3/d5c09c17fb5446728d3818bc4afeca69"
  },
  blockExplorers: {
    default: { name: "Etherscan", url: "https://etherscan.io" }
  },
  multicall: {
    address: "0xac1cE734566f390A94b00eb9bf561c2625BF44ea",
    blockCreated: 16944787
  },
  testnet: true
};
var coreDao = {
  id: 1116,
  name: "Core Dao",
  network: "coredao",
  nativeCurrency: {
    decimals: 18,
    name: "Core",
    symbol: "CORE"
  },
  rpcUrls: {
    default: "https://rpc.coredao.org"
  },
  blockExplorers: {
    default: {
      name: "CoreDao",
      url: "https://scan.coredao.org"
    }
  },
  multicall: {
    address: "0xcA11bde05977b3631167028862bE2a173976CA11",
    blockCreated: 11907934
  },
  testnet: false
};
var harmonyOne = {
  id: 16666e5,
  name: "Harmony One",
  network: "harmony",
  nativeCurrency: {
    name: "Harmony",
    symbol: "ONE",
    decimals: 18
  },
  rpcUrls: {
    default: "https://rpc.ankr.com/harmony"
  },
  blockExplorers: {
    default: {
      name: "Harmony Explorer",
      url: "https://explorer.harmony.one"
    }
  },
  multicall: {
    address: "0xca11bde05977b3631167028862be2a173976ca11",
    blockCreated: 24185753
  }
};
var moonbeam = {
  id: 1284,
  name: "Moonbeam",
  network: "moonbeam",
  nativeCurrency: {
    decimals: 18,
    name: "GLMR",
    symbol: "GLMR"
  },
  rpcUrls: {
    default: "https://moonbeam.public.blastapi.io"
  },
  blockExplorers: {
    default: {
      name: "Moonscan",
      url: "https://moonscan.io"
    }
  },
  multicall: {
    address: "0xcA11bde05977b3631167028862bE2a173976CA11",
    blockCreated: 609002
  },
  testnet: false
};
var celo = {
  id: 42220,
  name: "Celo",
  network: "celo",
  nativeCurrency: {
    decimals: 18,
    name: "CELO",
    symbol: "CELO"
  },
  rpcUrls: {
    default: "https://forno.celo.org"
  },
  blockExplorers: {
    default: {
      name: "Celo Explorer",
      url: "https://celoscan.io"
    }
  },
  multicall: {
    address: "0xcA11bde05977b3631167028862bE2a173976CA11",
    blockCreated: 13112599
  },
  testnet: false
};
var okc = {
  id: 66,
  name: "OKC",
  network: "okc",
  nativeCurrency: {
    decimals: 18,
    name: "OKT",
    symbol: "OKT"
  },
  rpcUrls: {
    default: "https://exchainrpc.okex.org"
  },
  blockExplorers: {
    default: {
      name: "oklink",
      url: "https://www.oklink.com/okc"
    }
  },
  multicall: {
    address: "0xca11bde05977b3631167028862be2a173976ca11",
    blockCreated: 10364792
  }
};
var aurora = {
  id: 1313161554,
  name: "Aurora",
  network: "aurora",
  nativeCurrency: {
    decimals: 18,
    name: "Ether",
    symbol: "ETH"
  },
  rpcUrls: {
    default: "https://mainnet.aurora.dev"
  },
  blockExplorers: {
    default: {
      name: "Aurorascan",
      url: "https://aurorascan.dev"
    }
  },
  multicall: {
    address: "0xca11bde05977b3631167028862be2a173976ca11",
    blockCreated: 62907816
  }
};
var metis = {
  id: 1088,
  name: "Metis",
  network: "metis",
  nativeCurrency: {
    decimals: 18,
    name: "Metis",
    symbol: "METIS"
  },
  rpcUrls: {
    default: "https://andromeda.metis.io/?owner=1088"
  },
  blockExplorers: {
    default: {
      name: "Metis Explorer",
      url: "https://explorer.metis.io"
    }
  },
  multicall: {
    address: "0xca11bde05977b3631167028862be2a173976ca11",
    blockCreated: 2338552
  }
};
var klaytn = {
  id: 8217,
  name: "Klaytn",
  network: "klaytn",
  nativeCurrency: {
    decimals: 18,
    name: "Klaytn",
    symbol: "KLAY"
  },
  rpcUrls: {
    default: "https://public-en-cypress.klaytn.net"
  },
  blockExplorers: {
    default: {
      name: "KlaytnScope",
      url: "https://scope.klaytn.com"
    }
  },
  multicall: {
    address: "0xcA11bde05977b3631167028862bE2a173976CA11",
    blockCreated: 96002415
  }
};
var xdc = {
  id: 50,
  name: "XinFin Network",
  network: "xdc",
  nativeCurrency: {
    decimals: 18,
    name: "XDC",
    symbol: "XDC"
  },
  rpcUrls: {
    default: "https://rpc.xinfin.network"
  },
  blockExplorers: {
    default: {
      name: "Blocksscan",
      url: "https://xdc.blocksscan.io"
    }
  },
  multicall: {
    address: "0xca11bde05977b3631167028862be2a173976ca11",
    blockCreated: 71542788
  }
};
var gnosis = {
  id: 100,
  name: "Gnosis",
  network: "gnosis",
  nativeCurrency: {
    decimals: 18,
    name: "Gnosis",
    symbol: "xDAI"
  },
  rpcUrls: {
    default: "https://rpc.gnosischain.com"
  },
  blockExplorers: {
    default: {
      name: "Gnosisscan",
      url: "https://gnosisscan.io"
    }
  },
  multicall: {
    address: "0xca11bde05977b3631167028862be2a173976ca11",
    blockCreated: 21022491
  }
};
var syscoin = {
  id: 57,
  name: "Syscoin Mainnet",
  network: "syscoin",
  nativeCurrency: {
    decimals: 18,
    name: "Syscoin",
    symbol: "SYS"
  },
  rpcUrls: {
    default: "https://rpc.syscoin.org"
  },
  blockExplorers: {
    default: {
      name: "SyscoinExplorer",
      url: "https://explorer.syscoin.org"
    }
  },
  multicall: {
    address: "0xcA11bde05977b3631167028862bE2a173976CA11",
    blockCreated: 287139
  }
};
var iotex = {
  id: 4689,
  name: "IoTeX",
  network: "iotex",
  nativeCurrency: {
    decimals: 18,
    name: "IoTeX",
    symbol: "IOTX"
  },
  rpcUrls: {
    default: "https://rpc.ankr.com/iotex"
  },
  blockExplorers: {
    default: {
      name: "IoTeXScan",
      url: "https://iotexscan.io"
    }
  },
  multicall: {
    address: "0xfe44a705D8871Be605EB10Cec74a45a6B21B1D19",
    blockCreated: 16978e3
  }
};
var telos = {
  id: 40,
  name: "Telos",
  network: "telos",
  nativeCurrency: {
    decimals: 18,
    name: "Telos",
    symbol: "TLOS"
  },
  rpcUrls: {
    default: "https://mainnet.telos.net/evm"
  },
  blockExplorers: {
    default: {
      name: "TelosScan",
      url: "https://www.teloscan.io"
    }
  },
  multicall: {
    address: "0x205A16470148e07939a9C0f24304eFE7febA28ED",
    blockCreated: 2465300
  }
};
var zksync = {
  id: 324,
  name: "zkSync Era",
  network: "zksync",
  nativeCurrency: {
    decimals: 18,
    name: "Ether",
    symbol: "ETH"
  },
  rpcUrls: {
    default: "https://mainnet.era.zksync.io"
  },
  blockExplorers: {
    default: {
      name: "zkSync Explorer",
      url: "https://explorer.zksync.io"
    }
  },
  multicall: {
    address: "0x2137b9e51Ff0Ba38AdB24ECd6AcB4f218d5C6b88",
    blockCreated: 35e4
  }
};
var scroll = {
  id: 534351,
  name: "Scroll",
  network: "scroll",
  nativeCurrency: {
    decimals: 18,
    name: "Scroll",
    symbol: "SCRL"
  },
  rpcUrls: {
    default: "https://scroll-mainnet.public.blastapi.io"
  },
  blockExplorers: {
    default: {
      name: "Scroll Explorer",
      url: "https://scrollscan.com"
    }
  },
  multicall: {
    address: "0x12c3a75512AB2aD6b01884Ad5326df96b8e873d5",
    blockCreated: 165832
  }
};
var fantom = {
  id: 250,
  name: "Fantom",
  network: "fantom",
  nativeCurrency: {
    decimals: 18,
    name: "Fantom",
    symbol: "FTM"
  },
  rpcUrls: {
    default: "https://rpc.ftm.tools"
  },
  blockExplorers: {
    default: {
      name: "FTMScan",
      url: "https://ftmscan.com"
    }
  },
  multicall: {
    address: "0xca11bde05977b3631167028862be2a173976ca11",
    blockCreated: 33001987
  }
};
export {
  aurora,
  avalandche,
  avalandcheFuji,
  bsc,
  bscTest,
  celo,
  coreDao,
  ethereum,
  fantom,
  fantomTestnet,
  gnosis,
  goerli,
  harmonyOne,
  iotex,
  klaytn,
  mainnet,
  metis,
  moonbeam,
  okc,
  rinkeby,
  scroll,
  syscoin,
  telos,
  xdc,
  zksync
};
