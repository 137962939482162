import { ChainId, ERC20Token } from '@pancakeswap/sdk'

export const WBNB_TESTNET = new ERC20Token(
  ChainId.BSC_TESTNET,
  '0x58051dbe967302A012aAD1EAEf22Cec1B75BB99B',
  18,
  'RTKN',
  'Rights Token',
)
export const SYRUP_TESTNET = new ERC20Token(
  ChainId.BSC_TESTNET,
  '0xF8700C5a5124628CF5B056E541C33BBD54Bd0df3',
  18,
  'Mil',
  'MilioTokens',
)
export const BAKE_TESTNET = new ERC20Token(
  ChainId.BSC_TESTNET,
  '0xE71d34aaF4EE405C9aDbF2a5efa18BB381067518',
  18,
  'GG',
  'GALAGOLD',
)
export const HBTC_TESTNET = new ERC20Token(
  ChainId.BSC_TESTNET,
  '0xe538354021967a38A615ab7160d60685936D305b',
  18,
  'Maccoin',
  'Maccoin',
)
export const WBTC_TESTNET = new ERC20Token(
  ChainId.BSC_TESTNET,
  '0x07BAB79C37bd4c19c80cce805422dBcE19AEC98E',
  18,
  'MetavCoin',
  'MetavCoin',
)
export const USDT_TESTNET = new ERC20Token(
  ChainId.BSC_TESTNET,
  '0xa06DB00Abd15E4DfEE59C1A32e28fbd10cAB95ef',
  18,
  'Promocoin',
  'Promocoin',
)

export const CAKE_MAINNET = new ERC20Token(
  ChainId.BSC,
  '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9',
  18,
  'USDC',
  'Binance-Peg USD Coin',
  'https://www.centre.io/usdc',
)

export const ROSH_TESTNET = new ERC20Token(
  ChainId.BSC,
  '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9',
  18,
  'USDC',
  'Binance-Peg USD Coin',
  'https://www.centre.io/usdc',
)

export const USDC_BSC = new ERC20Token(
  ChainId.BSC,
  '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9',
  18,
  'USDC',
  'Binance-Peg USD Coin',
  'https://www.centre.io/usdc',
)



export const USDC_ETH = new ERC20Token(
  ChainId.ETHEREUM,
  '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
  6,
  'USDC',
  'USD Coin',
)

export const USDC_RINKEBY = new ERC20Token(
  ChainId.RINKEBY,
  '0x4DBCdF9B62e891a7cec5A2568C3F4FAF9E8Abe2b',
  6,
  'tUSDC',
  'test USD Coin',
)

export const USDC_GOERLI = new ERC20Token(
  ChainId.GOERLI,
  '0x07865c6E87B9F70255377e024ace6630C1Eaa37F',
  6,
  'tUSDC',
  'test USD Coin',
)

export const USDT_BSC = new ERC20Token(
  ChainId.BSC,
  '0x55d398326f99059fF775485246999027B3197955',
  18,
  'USDT',
  'Tether USD',
  'https://tether.to/',
)

export const USDT_ETH = new ERC20Token(
  ChainId.ETHEREUM,
  '0xdAC17F958D2ee523a2206206994597C13D831ec7',
  6,
  'USDT',
  'Tether USD',
  'https://tether.to/',
)

export const BUSD_BSC = new ERC20Token(
  ChainId.BSC,
  '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  18,
  'BUSD',
  'Binance USD',
  'https://www.paxos.com/busd/',
)

export const BUSD_TESTNET = new ERC20Token(
  ChainId.BSC_TESTNET,
  '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9',
  6,
  'USDT',
  'Theter USD',
  'https://www.paxos.com/busd/',
)

export const BUSD_ETH = new ERC20Token(
  ChainId.ETHEREUM,
  '0x4Fabb145d64652a948d72533023f6E7A623C7C53',
  18,
  'BUSD',
  'Binance USD',
  'https://www.paxos.com/busd/',
)

export const BUSD_RINKEBY = new ERC20Token(
  ChainId.RINKEBY,
  '0x4e2442A6f7AeCE64Ca33d31756B5390860BF973E',
  18,
  'BUSD',
  'Binance USD',
  'https://www.paxos.com/busd/',
)

export const BUSD_GOERLI = new ERC20Token(
  ChainId.GOERLI,
  '0xb809b9B2dc5e93CB863176Ea2D565425B03c0540',
  18,
  'BUSD',
  'Binance USD',
  'https://www.paxos.com/busd/',
)

export const BUSD: Record<ChainId, ERC20Token> = {
  [ChainId.ETHEREUM]: BUSD_ETH,
  [ChainId.RINKEBY]: BUSD_RINKEBY,
  [ChainId.GOERLI]: BUSD_GOERLI,
  [ChainId.BSC]: BUSD_BSC,
  [ChainId.BSC_TESTNET]: BUSD_TESTNET,
}

export const CAKE = {
  [ChainId.BSC]: CAKE_MAINNET,
  [ChainId.BSC_TESTNET]: ROSH_TESTNET,
}

export const USDC = {
  [ChainId.BSC]: USDC_BSC,
  [ChainId.ETHEREUM]: USDC_ETH,
  [ChainId.RINKEBY]: USDC_RINKEBY,
  [ChainId.GOERLI]: USDC_GOERLI,
}

export const USDT = {
  [ChainId.BSC]: USDT_BSC,
  [ChainId.ETHEREUM]: USDT_ETH,
}

export const WBTC_ETH = new ERC20Token(
  ChainId.ETHEREUM,
  '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599',
  8,
  'WBTC',
  'Wrapped BTC',
)
